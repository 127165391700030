.container {
  padding: 1rem;
}
.selectContainer {
  margin-bottom: 0.7rem;
  .action {
    font-weight: 500;
    margin-bottom: 0;
    font-size: 14px;
  }
  .Upload {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    span {
      color: #009ca6;
      cursor: pointer;
    }
  }
  .emailContainer {
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #eef4f9;
    .inputContainer {
      background-color: white;
      display: flex;
      flex-direction: column;
      input,
      textarea {
        padding: 0.2rem;
        border: none;
        border-bottom: 1px solid hsl(0, 0%, 80%);
        font-size: 12px;
        width: 100%;
      }
    }
  }
  .checkbox {
    margin-top: 0.3rem;
    font-size: 12px;
  }
}
.spinner {
  height: 15px;
  width: 15px;
  margin-left: 3px;
}

.error {
  border: 1px solid red !important;
}
.errorText {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
.formMessage {
  font-size: 12px;
  text-align: justify;
}
.csvContainer {
  height: 100%;
  overflow-y: auto;
  .tabContainer {
    border-bottom: none !important;
    .tab {
      flex: 1;
      background-color: #38335e;
      position: relative;
      a {
        color: white !important;
        font-weight: 400 !important;
        font-size: 14px;
        text-align: center;
        &:hover {
          border-color: transparent !important;
        }
      }
      &:not(:last-child):after {
        content: '';
        position: absolute;
        right: 0px;
        background: transparent;
        top: 0px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0px 22px 21px;
        border-color: transparent transparent transparent #38335e;
      }
    }

    .activeTab {
      background-color: #2d9bf0;
      &:not(:last-child):after {
        background: #38335e;
        border-color: transparent transparent transparent #2d9bf0;
      }
      &:not(:first-child):before {
        content: '';
        position: absolute;
        right: 0px;
        background: transparent;
        top: 0px;
        left: 0px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0px 22px 21px;
        border-color: transparent transparent transparent #38335e;
      }
    }
  }
}
.uploadContainer {
  font-size: 14px;
  margin-bottom: 0.1rem;
  .title {
    font-weight: 600;
  }
  .value {
    text-transform: capitalize;
  }
  .dropzone {
    border: 1px dashed;
    padding: 0.3rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon {
      font-size: 40px;
      display: block;
    }
  }
  .errorMessage {
    margin-bottom: 0;
  }
}
.previewContainer {
  padding: 1rem;
  padding-bottom: 3rem;
  // height: 400px;
  // overflow-y: auto;
  .title {
    font-size: 14px;
    font-weight: 500;
  }
  .grid {
    height: 400px;
    margin-top: 1rem;
  }
}

.progressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    height: 150px;
    svg {
      height: 100%;
    }
  }
  .description {
    font-size: 14px;
  }
  .icon {
    font-size: 100px;
    color: #0ca4ac;
  }
  .errorIcon {
    font-size: 100px;
    color: red;
  }
  .spinner {
    color: #0ca4ac;
    height: 100px;
    width: 100px;
  }
}
