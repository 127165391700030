.login-page {
  .login-header {
    position: relative;

    .title {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      img {
        width: 80%;
      }
    }
  }

  .login-page-small {
    font-size: 0.8em;
  }

  .login-notice {
    color: orange;

    &:hover {
      color: rgb(206, 136, 6);
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }
}

.btn-light-login {
  background-color: #ffffff;
  border-color: #034a6d;
  color: #034a6d;

  &:hover,
  &:active,
  &:focus {
    background-color: #f0eeee;
    border-color: #023853;
    color: #023853;
  }
}

.btn-login {
  background-color: #034a6d;
  border-color: #034a6d;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: #023853;
    border-color: #023853;
    color: white;
  }

  &:disabled {
    background-color: #034a6d;
    border-color: #034a6d;
    color: white;
  }

  .spinner {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: calc(50% - 0.75rem);
  }
}

.text-darkblue {
  color: #034a6d;
}

.theme-io {
  background-color: #425362;
  min-height: 100vh;
  padding-bottom: 2rem;
  .home-icon {
    color: white;
  }
  .login-section {
    border: 1px solid #7b92a5;
    border-radius: 4px;
    .vector-img {
      opacity: 0;
    }
    label,
    .title-description,
    .forgot-text {
      color: white !important;
    }
    .back-btn {
      color: #a9a6a6;
    }
    .theme-card {
      background: transparent;
      .project-name {
        color: white;
      }
      .theme-button {
        background: #00a6b6;
        color: white;
        border: none;
        &:hover {
          background-color: #0696a3;
        }
      }
      .theme-card-body {
        border-top: 1px solid #7b92a5;
        padding: 0;
        margin: 24px;
        margin-top: 0;
      }
    }
  }
}
