.theme-io {
  .navbar-header {
    .setting-icons {
      display: block !important;
    }
    .profile-icons {
      flex: unset;
    }
  }
}
