.formContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .selectContainer {
    margin-bottom: 0.2rem;
  }
  .inputContainer {
    background-color: white;
    input,
    textarea {
      padding: 0.2rem;
      border: 1px solid hsl(0, 0%, 80%);
      font-size: 12px;
      width: 100%;
    }
  }
  .action {
    font-weight: 500;
    margin-bottom: 0.2rem;
    font-size: 14px;
  }
  .errorText {
    color: red;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .formatRoleText {
    color: lightseagreen;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .spinner {
    height: 15px;
    width: 15px;
    margin-left: 3px;
  }
}
