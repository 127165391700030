.descripton {
  font-size: 14px;
  // padding: 0.5rem 0;
  color: #273e61;
}
.member {
  color: #009ca6;
  font-weight: 500;
}
.action-text {
  color: #66094e;
  font-weight: 500;
  cursor: pointer;
}
.disabled-action-text {
  color: #66094e;
  font-weight: 500;
  cursor: not-allowed;
  opacity: 0.5;
}
