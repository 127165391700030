.container {
  padding: 1rem;
}
.selectContainer {
  margin-bottom: 0.7rem;
  .action {
    font-weight: 500;
    margin-bottom: 0;
    font-size: 14px;
    display: inline;
  }
    .inputContainer {
      background-color: white;
      display: flex;
      flex-direction: column;
      input{
        padding: 0.2rem;
        border: 1px solid hsl(0, 0%, 80%);
        font-size: 12px;
        width: 100%;
      }
    }
}
.spinner {
  height: 15px;
  width: 15px;
  margin-left: 3px;
}
.error {
  border: 1px solid red !important;
}
.errorText {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.mandatoryText {
  text-align: right;
  display: block;
  font-size: 10px;
}

// .container {
//   padding: 1rem;
// }
// .selectContainer {
//   margin-bottom: 0.7rem;
//   .action {
//     font-weight: 500;
//     margin-bottom: 0;
//     font-size: 14px;
//     width: 80px;
//     display: inline-block;
//     }
//     .inputContainer {
//       background-color: white;
//       display: inline-flex;
//       flex-direction: column;
//       margin-left: 10px;

//       input {
//         padding: 0.2rem;
//         border: 1px solid hsl(0, 0%, 80%);
//         font-size: 12px;
//         width: 350px;
//       }
//     }
// }
// .error {
//   border: 1px solid red !important;
// }
// .errorText {
//   color: red;
//   font-size: 12px;
//   margin-top: 4px;
// }
