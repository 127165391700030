.grid {
  height: 500px;
  margin-top: 1rem;
}
.actionContainer {
  width: 70%;

  .enable {
    accent-color: #009ca6;
  }
  .disable {
    accent-color: red;
  }
}
.portalContainer {
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
}
.spinner {
  height: 15px;
  width: 15px;
  margin-left: 3px;
}
