.overlayLoader {
  height: 100%;
  width: 100%;
  position: absolute;
  padding-top: 25%;
  left: 0;
  z-index: 9999;
  top: 0;
  background-color: rgb(160, 156, 156, 0.5);
}
