.autocomplete {
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 0.25rem;
  font-size: 0.8125rem
}

.autocomplete input {
  border: none;
  padding: 0.47rem 0.75rem;
  color: #495057
}

input:focus {
  outline: none;
}

.suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  border-radius: 0 0 0.25rem 0.25rem;
}

.suggestions li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
}

.autocomplete li:hover {
  background-color: #d4d4d4;
}

.successMessage {
  color: green;
  font-size: 12px;
  margin-top: -15px;
}
.errorMessage {
  margin-top: -15px;
}
.label {
  margin-bottom: -20px !important;
  font-size: 14px !important;
  font-weight: 500;
  color: black;
}
