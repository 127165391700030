.highlight {
  :global .dropdown {
    div[class^='select_buttonWrapper'] {
      button {
        border: 1px solid #f46a6a !important;
      }
    }
  }
  div {
    input {
      border: 1px solid #f46a6a !important;
    }
  }
}
