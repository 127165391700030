.roleWorkgroup {
  flex: 1;
  padding: 8px;
  padding-right: 0;
  cursor: pointer;
  gap: 5px;
  margin-bottom: 0.3rem;
  height: 38px;
  width: calc(100% - 20px);
  .titleContainer {
    flex: 1;
    color: #273e61;
    display: inline-block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    // .title {
    //   color: #273e61;
    //   display: inline-block;
    //   font-size: 14px;
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   margin-bottom: 0;
    // }
  }
  .activeTitle {
    color: #034a6d;
  }
  .group {
    color: #93a1b6;
    align-items: center;
    font-size: 12px;
    width: 40%;
    text-align: end;
  }
  .activeGroup {
    color: #034a6d;
    position: relative;
    left: 10px;
  }
}

.activeRole {
  background-color: #add2d4 !important;
}
.polygon {
  width: 20px;
  height: 38px;
  margin-left: -4px;
  img {
    height: 100%;
    width: 100%;
  }
}
