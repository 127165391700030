body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  font-family: Poppins, sans-serif;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin: 1rem;
}

.dtb-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}
.main-content {
  overflow-y: auto !important;
}
.dock-container {
  height: 100% !important;
}

.font-bold {
  font-weight: bold;
}
.flex-col {
  flex-direction: column;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}

.flex-1 {
  flex: 1;
}
.text-red {
  color: red;
}
.w-32 {
  width: 8rem;
}
.w-20 {
  width: 5rem;
}

.ag-header-row-column-filter,
.ag-paging-panel {
  background-color: #eef4f9 !important;
}
.ag-header-row-column,
.dock-nav,
.dock-tab {
  background-color: white !important;
}
.ag-paging-panel {
  border-top: unset !important;
}
.ag-input-field-input {
  border: none !important;
}
.ag-paging-description,
.ag-paging-row-summary-panel {
  color: #93a1b6 !important;
}
.tag {
  color: white;
  font-size: 12px;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
}
.success {
  background-color: green;
}
.failed {
  background-color: red;
}

.my-panel-extra-btn {
  cursor: pointer;
  color: red;
  display: inline-block;
  width: 18px;
  height: 24px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
  line-height: 30px;
  &:hover {
    opacity: 1;
  }
}

.page-content {
  padding-bottom: 30px !important;
  height: calc(100vh - 30px);
}
.view-container .tree-container .tree-node .node .title {
  margin-left: 0 !important;
}

.bg-transparent {
  background-color: transparent;
}
.text-black {
  color: #000 !important;
}
.dock-top .dock-bar {
  margin-left: 8px;
  padding-left: unset !important;
}
.dock-tab > div {
  padding-left: unset !important;
}

#miller-column .selectedTopic h6 {
  display: none !important;
}
#miller-column > div {
  position: relative;
  height: 250px;
  overflow: scroll;
  padding-left: 10px;
}

.miller-header {
  padding: 0 10px;
  .error-text {
    font-size: 12px;
    color: red;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.is-invalid {
  color: #f46a6a;
  font-size: 12px;
  margin-top: 4px;
}
.ag-overlay-no-rows-wrapper {
  align-items: flex-start !important;
  padding-top: 150px;
}

.form-label {
  margin-bottom: 0.2rem !important;
  font-size: 14px !important;
  font-weight: 500;
  color: black;
}
.dock-layout
  > :not(.dock-fbox)
  .dock-panel.dock-style-headless
  .dock-bar:hover {
  display: none !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dock-container {
  height: calc(100vh - (30px + 46px + 2px + 24px + 10px));
}
.ag-header-cell-resize {
  z-index: 1 !important;
}
.app-search {
  display: none;
}
.user-tab-header {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
}
.float-right {
  float: right;
}
.filter-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 70px auto auto auto auto auto;
}

.preview-mode {
  width: 100%;
  height: 100%;
  overflow: auto;
  .gjs-pn-views-container {
    display: none !important;
  }
  .gjs-off-prv {
    display: none !important;
  }
}

.hidden {
  display: none;
}
.show {
  display: block;
}
.dock-fbox {
  z-index: 260 !important;
}
.spinner {
  height: 15px;
  width: 15px;
  margin-left: 3px;
}
.size-sm {
  height: 0.75rem !important;
  width: 0.75rem !important;
}
.tooltip {
  margin-bottom: 10px !important;
}
.description {
  text-align: justify;
}
.grid-table {
  height: 100% !important;
}
.react-datepicker__time-container {
  width: 100px !important;
}
.react-datepicker__time-box {
  width: 100px !important;
}
.react-datepicker__navigation--next--with-time {
  right: 100px !important;
}

.language-dropdown {
  display: none !important;
}
