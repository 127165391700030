.custom-date-filter {
  width: 100%;
  min-height: calc(var(--ag-grid-size) * 4);
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 2px;
  background-color: #fff;
}

.custom-date-filter input[type='date'] {
  flex-grow: 1;
  min-height: calc(var(--ag-grid-size) * 4);
  border: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 28px 0 8px; /* Adjust padding to ensure text does not overlap with the icon */
  font-size: 14px;
  background: none;
  z-index: 1;
}

.custom-date-filter input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 4px; /* Adjust position for alignment */
  top: 50%;
  transform: translateY(-50%);
  height: 12px; /* Ensure the icon is a reasonable size */
  width: 12px;
  opacity: 1;
  cursor: pointer;
}

.custom-date-filter .placeholder {
  position: absolute;
  left: 8px; /* Adjusted for better alignment */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 14px;
  color: transparent;
  z-index: 0;
  line-height: 32px; /* Vertically align text */
}

.custom-date-filter.has-value .placeholder,
.custom-date-filter input[type='date']:focus + .placeholder {
  visibility: hidden;
}

/* Ensure the parent container aligns items centrally */
.ag-floating-filter-body {
  display: flex;
  align-items: center;
}
