.mainContainer {
  background-color: #eef4f9;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    padding: 0.5rem;
    gap: 0.5rem;

    .role {
      background-color: white;
      border: none;
      outline: none;
      color: black;
    }
  }

  .roleList {
    margin-top: 0.5rem;
    padding-bottom: 2rem;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .notFound {
      text-align: center;
      font-size: 14px;
    }
  }
}
