.formContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .spinner {
    height: 15px;
    width: 15px;
    margin-left: 3px;
  }
  .form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p {
      margin-bottom: 0px !important;
    }
  }
}
