.formContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .spinner {
    height: 15px;
    width: 15px;
    margin-left: 3px;
  }

  .button {
    width: 350px;
    justify-content: space-between;
    span {
      font-size: 13px !important;
    }
  }
}
