.roleContainer {
  padding: 22px;
  height: 100%;
  .role {
    margin-bottom: 1rem;
    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: #023853;
    }
  }
}
