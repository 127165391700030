.custom_filter_input {
  width: 100%;
  border: 1px solid #ccc; /* Apply border to all sides */
  height: 25px;
  margin-top: auto;
  border-radius: 5px;
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: border-color 0.3s ease; /* Smooth transition for border color change */
}

.custom_filter_input:focus {
  border-color: #A1DBFA;
  box-shadow: 0 0 10px rgba(108, 189, 189, 0.8);
}
