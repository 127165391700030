.container {
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .innerContainer {
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
  }
  .innerContainerWithButtons {
    padding: 0 1rem 1rem 1rem;
    height: 100%;
    overflow-y: auto;
  }
  .gridPortalContainer {
    height: 100%;
    min-height: 330px;
    display: flex;
    flex-direction: column;
  }
  .profileDetail {
    padding: 15px;
    background-color: white;
    .detail {
      img {
        height: 70px;
        width: 70px;
        border-radius: 50%;
      }
      span {
        font-size: 13px;
        font-weight: 300;
      }
      .icon {
        color: #676767;
      }
    }
  }
  .layout {
    height: 100%;
  }
}

.grid {
  height: 90%;
  margin-top: 1rem;
}

.bgStyle {
  background-color: #f8f8fb;
  height: 100%;
}

.actionContainer {
  width: 70%;

  .enable {
    accent-color: #009ca6;
  }
  .disable {
    accent-color: red;
  }
}

.spinner {
  height: 15px;
  width: 15px;
  margin-left: 3px;
}

.red {
  color: red;
}

.reason {
  font-size: 12px;
  margin-left: 0.2rem;
}

.emailSubject {
  .title {
    font-weight: 500;
    margin: 0;
  }
  .sender {
    span {
      font-weight: 500;
    }
  }
}
.body {
  font-size: 14px;
  font-weight: 400;
}
.magicKey {
  display: flex;
  flex-direction: row;
  align-items: center;
  .magicKeyDisplay {
    margin-left: -25px;
    color: gray;
  }
}
.accessRequestBtn {
  color: blue;
  cursor: pointer;
}
