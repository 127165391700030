.nameContainer {
  width: 220px;
  .fileName {
    font-size: 12px;
    cursor: pointer;
    margin: 0;
  }
}
.infoTooltipIcon {
  height: 20px;
  width: 20px;
  margin: 0;
  svg {
    height: 100%;
    width: 100%;
  }
}
