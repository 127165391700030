.workgroup-container {
  padding: 0.5rem;
  background: #eef4f9;
  height: 100%;
  overflow-y: auto;
}
.wr-container {
  .header {
    gap: 10px;

    .buttons {
      gap: 7px;
      .button {
        .icon {
          margin-right: 0.4rem;
        }
        padding: 0.1rem 0.4rem;
        font-size: 12px;
        border: 1px solid #273e61;
      }
    }
  }
  .headerText {
    font-size: 14px;
    .role-name {
      white-space: nowrap;
      color: #919191;
    }
    .role-value {
      font-weight: 500;
      margin-left: 5px;
      margin-bottom: 0px;
      color: black;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cursor-pointer {
      cursor: pointer;
    }
    .role-workgroup {
      margin-left: 5px;
      color: #009ca6;
    }
  }

  .description {
    margin-top: 0.2rem;
    font-size: 14px;
  }
}

.workgroup {
  margin: 0.4rem 0rem;
  background-color: #add2d4;
  padding: 0.5rem;
  border-radius: 0.4rem;

  .select-container {
    gap: 5px;
    cursor: pointer;
    font-size: 12px;
    .select-title {
      color: #273e61;
      font-weight: 400;
      .select-role-name {
        font-weight: 600;
      }
    }

    button {
      background-color: #273e61;
      font-size: 12px;
      border: none;
      outline: none;
      color: white;
      height: 30px;
      padding: 0 1rem;
      border-radius: 5px;
    }
  }
}
.defaultBlankText {
  color: red;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 14px;
}
